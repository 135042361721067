import BpkImage, {
  withLazyLoading,
  withLoadingBehavior,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { IndexBlockPanelProps } from 'common-types/types/IndexBlockPanel';

import STYLES from './IndexBlockPanel.module.scss';

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadedImage = withLoadingBehavior(
  withLazyLoading(BpkImage, documentIfExists),
);

const IndexBlockPanel = ({
  dataList,
  desc,
  isMobile,
  title,
}: IndexBlockPanelProps) => (
  <div className={STYLES.IndexBlockPanel} data-testid="indexBlockPanel">
    <div className={STYLES.IndexBlockPanel__container}>
      {title && (
        <BpkText
          tagName="h2"
          textStyle={isMobile ? TEXT_STYLES.heading3 : TEXT_STYLES.heading2}
          className={STYLES.IndexBlockPanel__title}
        >
          {title}
        </BpkText>
      )}
      {desc && (
        <BpkText
          tagName="p"
          className={STYLES.IndexBlockPanel__desc}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      )}
      <div className={STYLES.IndexBlockPanel__itemList}>
        {dataList.map((item) => (
          <article key={item.title} className={STYLES.IndexBlockPanel__item}>
            <div className={STYLES.IndexBlockPanel__imgBox}>
              <LazyLoadedImage altText="" aspectRatio={1} src={item.img} />
            </div>
            <div className={STYLES.IndexBlockPanel__itemHead}>
              <BpkText
                tagName="h3"
                textStyle={
                  isMobile ? TEXT_STYLES.heading4 : TEXT_STYLES.heading3
                }
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
            <div className={STYLES.IndexBlockPanel__itemBody}>
              <BpkText
                tagName="p"
                textStyle={TEXT_STYLES.bodyDefault}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          </article>
        ))}
      </div>
    </div>
  </div>
);

export default IndexBlockPanel;
